<template>
  <v-app>
    <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-alert v-model="success" type="success" dismissible>
        {{dialogText}}
      </v-alert>
      <v-alert v-model="error" type="error" dismissible>
        {{dialogText}}
      </v-alert>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size:30px">
          ユーザセンサ設定
        </v-card-title>
        <v-row class="pa-4" justify="space-between">
        
          <!-- 画面左部ツリー -->
          <v-col cols="5">
            <v-treeview
              activatable
              :items="items"
              :active.sync="active"
              :open.sync="open"
              open-on-click
            >
            </v-treeview>
          </v-col>
          <!-- 画面左部ツリー -->
          
          <v-divider vertical></v-divider>
          
          <!-- 画面右部 -->
          <v-col class="d-flex text-center">
            <v-scroll-y-transition mode="out-in">
              <!--未選択-->
              <div
                v-if="!selected"
                class="text-h6 text--lighten-1 font-weight-light"
                style="align-self: center;"
              >
                センサを選択して下さい。
              </div>
              <!--選択中-->
              <v-card
                v-else
                :key="selected.id"
                class="pt-6 mx-auto"
                flat
                width="95%"
              >
                <v-switch v-model="mailenabled" label="メール送信有効/無効">
                </v-switch>
                <v-switch v-model="deviceenabled" label="センサ有効/無効">
                </v-switch>
                <!--
                <v-text-field v-model="intervalwarning" label="警告通知メール送信インターバル">
                </v-text-field>
                <v-text-field v-model="intervaldisconnected" label="切断検知メール送信インターバル">
                </v-text-field>
                -->
                <!--ボタン部-->
                <v-row class="pa-4">
                  <v-spacer></v-spacer>
                  <v-btn small v-bind:disabled="!selected" v-on:click.stop="setting(getUserDev)" color="primary" style="font-size:16px" disable>設定</v-btn>
                </v-row>
              </v-card>
              
            </v-scroll-y-transition>
          </v-col>

        </v-row>
      </v-card>
      <v-row class="pa-4">
      </v-row>
    </v-form>
    </v-container>
  </v-app>
</template>
<script>
  
  export default {
    data: () => ({
      //表示・編集用ユーザ情報
      id:null,
      mailenabled:true,
      deviceenabled:true,
      intervalwarning:null,
      intervaldisconnected:null,
      loginuser:null,
      
      active: [],
      open: [],
      items: [],
      userdevs: [],
      sensorgroup: [],
      
      hosturl: process.env.VUE_APP_APIURL,
      
      dialogText:null,
      
      success:false,
      error:false,
      
      valid: true,

    }),
    computed: {
      selected () {
        if (!this.active.length) return undefined

        const activeid = this.active[0];
        var dev = null;
        for(var i = 0; i < this.items.length; i++){
          dev = this.items[i].children.find(item => item.id === activeid)
          if(dev !== undefined){
            break;
          }
        }
        if(dev===undefined){
          return
        }
        this.setdata(dev);
        return dev
      },
    },
    methods: {
      //ユーザセンサ情報取得
      getUserDev:function(){
        //画面左表示用
        var usergroup = this.$store.state.sensorgroup;
        this.getSensorInformation(usergroup);
        //画面右表示用
        this.userdevs = new Array()
        if(this.loginuser){
          var userdevurl = this.hosturl + "/user_dev/" + this.loginuser.username
          this.$axios.get(userdevurl).then(res => {
            if(res){
              this.userdevs = res['data'][this.loginuser.username]
            }
          })
          .catch((err) => {
            console.log(err);
            this.dialogText = 'センサの取得に失敗しました。';
            this.success = false;
            this.error = true;
            this.returnTop('auto');
          });
        }
        //選択状態初期化
        this.open = [];
        this.active = [];
      },
      getSensorInformation:function(usergroup){
        var newGroupList = new Array();
        usergroup.forEach(function( groupid ){
          var apiurl = this.hosturl + "/sensors/?sensorgroupid=" + groupid;
          var newItems = new Array();
          this.$axios.get(apiurl).then(res => {
            if(res['data']){
              res['data'].forEach(function ( data ) {
                data['id'] = data['dev_id'];
                data['name'] = data['dev_name'];
                newItems.push(data)
              });
            }
            newItems.sort(function(a, b){
              if(a.name > b.name){
                return 1;
              } else {
                return -1;
              }
            });
            var groupname = null;
            console.log(this.sensorgroup)
            for(var i=0; i<this.sensorgroup.length; i++){
              if(this.sensorgroup[i].sensorgroupid == groupid){
                groupname = this.sensorgroup[i].sensorgroup_name;
                break;
              }
            }
            var listitem = {
              id:groupid,
              name:groupname,
              children:newItems
            }
            newGroupList.push(listitem);
            newGroupList.sort(function(a, b){
              if(a.name > b.name){
                return 1;
              } else {
                return -1;
              }
            });
          })
          .catch((err) => {
            console.log(err);
            this.dialogText = groupid + 'のセンサの取得に失敗しました。';
            this.success = false;
            this.error = true;
            this.returnTop('auto');
          });
        }, this);
        this.items = newGroupList;
      },
      getSensorGroup:function(){
        this.sensorgroup = new Array();
        var apiurl = this.hosturl + "/sensor_group"
        this.$axios.get(apiurl, null).then(res => {
          this.sensorgroup = res.data;
          this.getUserDev(this.sortGroupList);
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサグループの取得に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      //センサ情報表示
      setdata: function(dev){
        this.id = dev.id
        this.mailenabled = true
        this.deviceenabled = true
        this.intervalwarning = 60
        this.intervaldisconnected = 60
        
        for(var i = 0; i < this.userdevs.length; i++){
          if(this.userdevs[i]["dev_id"] === this.id){
            this.mailenabled = this.userdevs[i]["mail_enabled"]
            this.deviceenabled = this.userdevs[i]["device_enabled"]
            this.intervalwarning = this.userdevs[i]["mail_interval_caution_warning"]
            this.intervaldisconnected = this.userdevs[i]["mail_interval_disconnected"]
            
            break
          }
        }
      },
      //センサ情報設定
      setting: function(callback){
        var apiurl = this.hosturl + "/user_dev/" + this.loginuser.username + "/?dev_id=" + this.id
        //PUT用パラメータ
        var param = {
          "mail_enabled": this.mailenabled,
          "device_enabled": this.deviceenabled,
          "mail_interval_caution_warning": this.intervalwarning,
          "mail_interval_disconnected": this.intervaldisconnected
        }
        
        this.$axios.put(apiurl, param).then(res => {
          console.log(res)
          this.dialogText = 'センサ情報の設定が完了しました。';
          this.success = true;
          this.error = false;
          this.returnTop('auto');
          callback();
        })
        .catch((err) => {
          console.log(err);
          this.dialogText = 'センサ情報の設定に失敗しました。';
          this.success = false;
          this.error = true;
          this.returnTop('auto');
        });
      },
      returnTop(action) {
        window.scrollTo({
          top: 0,
          behavior: action
        })
      },
    },
    mounted() {
      this.getSensorGroup();
      this.loginuser = this.$store.state.user;
    }
  }
</script>